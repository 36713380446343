:root {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle: hsl(230, 22%, 74%);
  --toggle-start: hsl(210, 78%, 56%);
  --toggle-end: hsl(146, 68%, 55%);
  --background: hsl(230, 17%, 14%);
  --dark-topbg: hsl(232, 19%, 15%);
  --cardbg: hsl(228, 28%, 20%);
  --text1: hsl(228, 34%, 66%);
  --text2: hsl(0, 0%, 100%);
  --text-heading: hsl(0, 0%, 100%);
  --card-hover: hsl(228, 25%, 27%);
  --horizontal-line: hsl(235, 17%, 26%);
}

[data-theme="light"] {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle: hsl(230, 22%, 74%);
  --toggle-start: hsl(232, 24%, 74%);
  --toggle-end: hsl(232, 24%, 74%);
  --background: hsl(0, 0%, 100%);
  --light-topbg: hsl(225, 100%, 98%);
  --cardbg: hsl(222, 50%, 96%);
  --text1: hsl(228, 12%, 44%);
  --text2: hsl(230, 17%, 14%);
  --text-heading: hsl(228, 12%, 44%);
  --card-hover: hsl(232, 33%, 91%);
  --color-light: hsl(225, 16%, 15%);
  --horizontal-line: hsl(231, 9%, 58%);
}
