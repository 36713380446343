html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  background: var(--background);
  color: var(--text2);
}

.container {
  padding: 0 1.5625rem;
  max-width: 69.375rem;
  margin: 0 auto 2.875rem;
}
@media (min-width: 70em) {
  .container {
    padding: 0;
  }
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 1.75rem;
  line-height: 1.1;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

:root {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle: hsl(230, 22%, 74%);
  --toggle-start: hsl(210, 78%, 56%);
  --toggle-end: hsl(146, 68%, 55%);
  --background: hsl(230, 17%, 14%);
  --dark-topbg: hsl(232, 19%, 15%);
  --cardbg: hsl(228, 28%, 20%);
  --text1: hsl(228, 34%, 66%);
  --text2: hsl(0, 0%, 100%);
  --text-heading: hsl(0, 0%, 100%);
  --card-hover: hsl(228, 25%, 27%);
  --horizontal-line: hsl(235, 17%, 26%);
}

[data-theme=light] {
  --lime-green: hsl(163, 72%, 41%);
  --bright-red: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle: hsl(230, 22%, 74%);
  --toggle-start: hsl(232, 24%, 74%);
  --toggle-end: hsl(232, 24%, 74%);
  --background: hsl(0, 0%, 100%);
  --light-topbg: hsl(225, 100%, 98%);
  --cardbg: hsl(222, 50%, 96%);
  --text1: hsl(228, 12%, 44%);
  --text2: hsl(230, 17%, 14%);
  --text-heading: hsl(228, 12%, 44%);
  --card-hover: hsl(232, 33%, 91%);
  --color-light: hsl(225, 16%, 15%);
  --horizontal-line: hsl(231, 9%, 58%);
}

:root {
  --font-inter: "Inter", sans-serif;
}

.header {
  margin-top: 2.25rem;
}
@media (min-width: 40em) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header__title {
  margin-bottom: 1.6875rem;
}
.header__subtitle {
  font-size: 0.875rem;
  color: var(--text1);
  font-weight: 500;
}
.header__toggle {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}
@media (max-width: 39.9375em) {
  .header__toggle {
    padding: 30px 0;
  }
}

@media (max-width: 39.9375em) {
  .horizontal-line {
    width: 100%;
    height: 0.0625rem;
    background: var(--horizontal-line);
  }
}
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-gap: 1.875rem;
}

.card-design {
  padding: 1.5625rem;
  text-align: center;
  background: var(--cardbg);
  border-radius: 0.3125rem;
  color: var(--text1);
}

.card-design:hover {
  background-color: var(--card-hover);
  cursor: pointer;
}

.card {
  display: flex;
  flex-direction: column;
  place-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.card--one {
  border-top: 0.25rem solid var(--facebook);
}
.card--two {
  border-top: 0.25rem solid var(--twitter);
}
.card--three::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 0.25rem;
  width: 100%;
  background: linear-gradient(255deg, var(--instagram-end) 0%, var(--instagram-middle) 50.91%, var(--instagram-start) 100%);
}
.card--four {
  border-top: 0.25rem solid var(--youtube);
}
.card__handles {
  display: flex;
  place-content: center;
  align-items: center;
}
.card__handles__img {
  margin-right: 0.5rem;
}
.card__handles__uname {
  font-size: 0.75rem;
  font-weight: bold;
}
.card__count {
  margin: 1.75rem 0 1.5625rem 0;
}
.card__count__heading {
  font-size: 3.5rem;
  color: var(--text2);
  letter-spacing: -0.125rem;
  line-height: 1;
  margin-bottom: 0.1875rem;
}
.card__count__text {
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: 0.3125rem;
  text-transform: uppercase;
}
.card__change {
  display: flex;
  place-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--lime-green);
}
.card__change__arrow {
  margin-right: 0.25rem;
}
.card__change__textred {
  color: var(--bright-red);
}
.card--baseline {
  align-self: baseline;
}

.toggle {
  border: none;
}
.toggle label {
  align-self: center;
  color: var(--text1);
  font-weight: 400;
  color: var(--text1);
}
.toggle input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3rem;
  height: 1.5rem;
  background: linear-gradient(225deg, var(--toggle-end) 0%, var(--toggle-start) 98.02%);
  border-radius: 3.125rem;
  position: relative;
  transition: all 150ms ease-in-out;
  border: none;
  outline: none;
}
.toggle input[type=checkbox]:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  bottom: 0;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background-color: var(--background);
  transition: all 150ms ease-in-out;
}
.toggle input[type=radio]:checked {
  background: linear-gradient(225deg, var(--toggle-end) 0%, var(--toggle-start) 98.02%);
}
.toggle input[type=checkbox]:checked:before {
  transform: translate(125%);
  background: var(--background);
  transition: all 150ms ease-in-out;
}

.stat--heading {
  color: var(--text-heading);
}

.overview__views {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}
.overview__views--text {
  font-size: 0.875rem;
}
.overview__arrnum {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.overview__arrnum--vnum {
  font-size: 1.5625rem;
  color: var(--text2);
  align-self: baseline;
  margin-top: 1.4375rem;
}
.overview__arrnum img {
  margin-right: 0.25rem;
}