@use "../util" as *;

.toggle {
  border: none;

  &__wrapper {
  }

  label {
    align-self: center;
    color: var(--text1);
    font-weight: 400;
    color: var(--text1);
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: rem(48);
    height: rem(24);
    background: linear-gradient(
      225deg,
      var(--toggle-end) 0%,
      var(--toggle-start) 98.02%
    );
    border-radius: rem(50);
    position: relative;
    transition: all 150ms ease-in-out;
    border: none;
    outline: none;
  }

  input[type="checkbox"]:before {
    content: "";
    position: absolute;
    top: rem(3);
    bottom: 0;
    left: rem(3);
    width: rem(18);
    height: rem(18);
    border-radius: 50%;
    background-color: var(--background);
    transition: all 150ms ease-in-out;
  }

  input[type="radio"]:checked {
    background: linear-gradient(
      225deg,
      var(--toggle-end) 0%,
      var(--toggle-start) 98.02%
    );
  }

  input[type="checkbox"]:checked:before {
    transform: translate(125%);
    background: var(--background);
    transition: all 150ms ease-in-out;
  }
  // input[type="radio"] {
  //   width: rem(26);
  //   height: rem(26);
  //   margin: 2px -2px 0;
  //   opacity: 0;
  //   cursor: pointer;
  //   border: none;

  //   &:focus ~ .toggle__switcher {
  //     border: 2px solid white;
  //   }
  // }

  // &__background {
  //   background: linear-gradient(
  //     225deg,
  //     var(--toggle-end) 0%,
  //     var(--toggle-start) 98.02%
  //   );
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   border-radius: rem(12);
  //   display: block;
  //   pointer-events: none;
  // }

  // &__switcher {
  //   position: absolute;
  //   top: rem(3);
  //   left: rem(3);
  //   width: rem(18);
  //   height: rem(18);
  //   border-radius: 50%;
  //   background-color: var(--background);
  //   transition: all 150ms ease-in-out;
  // }

  // #light:checked ~ .toggle__switcher {
  //   left: calc(100% - 21px);
  //   right: 3px;
  // }
}
