@use "../util" as *;

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: rem(28);
  line-height: 1.1;
}

h2 {
  font-size: rem(24);
  margin-bottom: rem(24);
}

a,
a:visited,
a:active {
  text-decoration: none;
}
